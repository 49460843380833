// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-characters-js": () => import("/opt/build/repo/src/pages/characters.js" /* webpackChunkName: "component---src-pages-characters-js" */),
  "component---src-pages-characters-andy-js": () => import("/opt/build/repo/src/pages/characters/andy.js" /* webpackChunkName: "component---src-pages-characters-andy-js" */),
  "component---src-pages-characters-chloe-js": () => import("/opt/build/repo/src/pages/characters/chloe.js" /* webpackChunkName: "component---src-pages-characters-chloe-js" */),
  "component---src-pages-characters-cynthia-js": () => import("/opt/build/repo/src/pages/characters/cynthia.js" /* webpackChunkName: "component---src-pages-characters-cynthia-js" */),
  "component---src-pages-characters-julia-js": () => import("/opt/build/repo/src/pages/characters/julia.js" /* webpackChunkName: "component---src-pages-characters-julia-js" */),
  "component---src-pages-characters-mallorie-js": () => import("/opt/build/repo/src/pages/characters/mallorie.js" /* webpackChunkName: "component---src-pages-characters-mallorie-js" */),
  "component---src-pages-characters-morris-js": () => import("/opt/build/repo/src/pages/characters/morris.js" /* webpackChunkName: "component---src-pages-characters-morris-js" */),
  "component---src-pages-characters-phillip-js": () => import("/opt/build/repo/src/pages/characters/phillip.js" /* webpackChunkName: "component---src-pages-characters-phillip-js" */),
  "component---src-pages-characters-robert-js": () => import("/opt/build/repo/src/pages/characters/robert.js" /* webpackChunkName: "component---src-pages-characters-robert-js" */),
  "component---src-pages-characters-shekhloov-js": () => import("/opt/build/repo/src/pages/characters/shekhloov.js" /* webpackChunkName: "component---src-pages-characters-shekhloov-js" */),
  "component---src-pages-characters-steven-js": () => import("/opt/build/repo/src/pages/characters/steven.js" /* webpackChunkName: "component---src-pages-characters-steven-js" */),
  "component---src-pages-characters-symphonie-js": () => import("/opt/build/repo/src/pages/characters/symphonie.js" /* webpackChunkName: "component---src-pages-characters-symphonie-js" */),
  "component---src-pages-characters-tuesday-js": () => import("/opt/build/repo/src/pages/characters/tuesday.js" /* webpackChunkName: "component---src-pages-characters-tuesday-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-learn-more-js": () => import("/opt/build/repo/src/pages/learn-more.js" /* webpackChunkName: "component---src-pages-learn-more-js" */),
  "component---src-pages-testquiz-js": () => import("/opt/build/repo/src/pages/testquiz.js" /* webpackChunkName: "component---src-pages-testquiz-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

